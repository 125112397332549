import { getFunctions } from '@firebase/functions';
import React from 'react'
import { FirebaseAppProvider, FunctionsProvider, useFirebaseApp } from 'reactfire'

const firebaseConfig = {
  apiKey: "AIzaSyA4SO3dHPl56v9j0tlPkZI74ZkPdzNhTXQ",
  authDomain: "re-chatfuel.firebaseapp.com",
  databaseURL: "https://re-chatfuel.firebaseio.com",
  projectId: "re-chatfuel",
  storageBucket: "re-chatfuel.appspot.com",
  messagingSenderId: "121223688864",
  appId: "1:121223688864:web:a108688194f95819068fc5"
};

const FirebaseSDK = ({ children }) => {
  const app = useFirebaseApp()
  return (
    <FunctionsProvider sdk={getFunctions(app)}>
      {children}
    </FunctionsProvider>
    )
}

const FirebaseProvider = ({ children }) => {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseSDK>
        {children}
      </FirebaseSDK>
    </FirebaseAppProvider>

  )
}

export default FirebaseProvider
