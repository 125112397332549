import React from 'react';
import {
  ChakraProvider,
} from '@chakra-ui/react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { extendTheme } from "@chakra-ui/react"

import Vote2021 from './modules/Vote2021'

const customTheme = extendTheme({
  fonts: {
    heading: "'Noto Sans TC'",
    body: "'Noto Sans TC'",
  },
})

function App() {
  return (
    <ChakraProvider theme={customTheme}>
      <Router basename="/app">
        <Switch>
          <Route path="/vote2021">
            <Vote2021 />
          </Route>
        </Switch>
      </Router>
    </ChakraProvider>
  );
}

export default App;
