import React, { forwardRef, useState } from 'react';
import {
  Container,
  Text,
  VStack,
  Button,
  Slider,
  SliderTrack,
  Box,
  SliderThumb,
  Flex,
  Icon,
  useToast ,
} from '@chakra-ui/react';
import { useSearchParam } from 'react-use';
import { useFunctions } from 'reactfire';
import { httpsCallable } from '@firebase/functions';
import { useForm, Controller } from "react-hook-form";
// import { FaPeace } from 'react-icons/fa'
import { ReactComponent as Vote } from './vote.svg'
import { ReactComponent as Submit } from './submit.svg'
import { Global } from '@emotion/react';
import { range } from 'lodash';

const SliderBlock = forwardRef(({ onChange, name, value, title }, ref) => {
  return (
    <VStack width="100%" maxW="480px" px="4">
      <Text fontWeight="700">{title}</Text>
      <Slider
        defaultValue={0}
        min={-100}
        max={100}
        onChange={onChange}
        name={name}
        ref={ref}
      >
        <SliderTrack overflow="visible" rounded="0" bg="#4D4D4D">
          <Box
            position="absolute"
            bg="white"
            style={{
              [value > 0 ? 'left' : 'right']: '50%',
              [value < 0 ? 'left' : 'right']: `${(100 - Math.abs(value)) / 200 * 100}%`,
            }}
            top="0"
            bottom="0"
          />
          {range(11).map(i => (
            <Box
              key={i}
              width="2px"
              position="absolute"
              left={`${i * 10}%`}
              bottom="0"
              height="200%"
              bg="white"
              transform="translateX(-50%)"
            />
          ))}
          {/* <SliderFilledTrack /> */}
        </SliderTrack>
        <SliderThumb boxSize={6}>
          <Icon as={Vote} />
        </SliderThumb>
      </Slider>
      <Flex justifyContent="space-between" width="full">
        <Text fontSize="sm">非常不同意</Text>
        <Text fontSize="sm">非常同意</Text>
      </Flex>
    </VStack>
  )
})

const titles = [
  '你是否同意重啟核四？',
  '你是否同意禁止萊豬進口？',
  '你是否同意公投綁大選？',
  '你是否同意三接遷離藻礁？',
]

function Vote2021() {
  const toast = useToast()
  const functions = useFunctions();
  const onVoteInput = httpsCallable(functions, 'onVoteInput');
  const { control, handleSubmit } = useForm();
  const [saving, setSaving] = useState()
  const [cantClose, setClose] = useState()
  const uid = useSearchParam('uid')
  const qid = useSearchParam('qid')
  const handleSave = async (data) => {
    setSaving(true)
    try {
      await onVoteInput({
        uid,
        qid,
        value: data.vote,
      })
      handleClose()
    } catch (e) {
      // alert(e.message)
      toast({
        title: e.message,
        status: 'error',
        isClosable: true,
      })
      setSaving(false)
    }
  }
  const handleClose = () => {
    window.MessengerExtensions.requestCloseBrowser(function success() {
      // webview closed
    }, function error(err) {
      setClose(true)
    });
  }
  return (
    <>
      <Global styles={{ body: { background: 'black' } }} />
      <Container textAlign="center" fontSize="xl" py="4" color="white">
        <VStack spacing={8} as="form" onSubmit={handleSubmit(handleSave)}>
          {titles.map((title, i) => (
            <Controller
              key={i}
              name={`vote.${i}`}
              control={control}
              defaultValue="0"
              render={({ field }) => <SliderBlock title={title} {...field} />}
            />
          ))}
          {cantClose
            ? <Text>已收到你的回覆，請直接按[X]關閉視窗</Text>
            : (
              <Button width="8em" height="auto" variant="unstyled" isLoading={saving} type="submit">
                <Submit style={{ width: '100%', height: 'auto' }} />
              </Button>
            )
          }
        </VStack>
      </Container>
    </>
  );
}

export default Vote2021;
